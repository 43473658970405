<template>
  <div class="Dashboard__View">
    <div class="Dashboard__Widgets">
      <div class="DashBoard__Widgets__Indicators">
        <div v-if="$store.state.profile.sosna" class="DashBoard__Widgets__Indicators__Item">
          <div class="DashBoard__Widgets__Indicators__Item__Box">
            <div class="amount">
              <img style="min-width: 80px;" src="../assets/Sosna.jpg" alt="">
            </div>
          </div>
        </div>
        <div class="DashBoard__Widgets__Indicators__Item">
          <h3>רכבים מנוהלים</h3>
          <div class="DashBoard__Widgets__Indicators__Item__Box">
            <div class="amount">
              {{ managedVehicleCount }}
            </div>
          </div>
        </div>
        <div class="DashBoard__Widgets__Indicators__Item">
          <h3>רכבים לא מנוהלים</h3>
          <div class="DashBoard__Widgets__Indicators__Item__Box">
            <div class="amount">
              {{ unManagedVehicleCount }}
            </div>
          </div>
        </div>
        <div class="DashBoard__Widgets__Indicators__Item">
          <h3>דוחות שלא שולמו</h3>
          <div class="DashBoard__Widgets__Indicators__Item__Box">
            <div class="amount">
              {{ unpaidReportCount }}
            </div>
          </div>
        </div>
        <div class="DashBoard__Widgets__Indicators__Item">
          <h3>סכום לתשלום</h3>
          <div class="DashBoard__Widgets__Indicators__Item__Box">
            <div class="amount">
              {{ unpaidAmount | currency }}
            </div>
          </div>
        </div>
      </div>
      <div class="Charts">
        <div class="ml-3">
          <b-loading
            :is-full-page="false"
            v-model="isLoadingPie"
            :can-cancel="false"
          />
          <div v-if="!isLoadingPie && hasPiedata">
            <h1>פילוח דוחות ע"פ סטטוס לשנת {{ new Date().getFullYear() }}</h1>
            <Pie v-if="pie" :chart-data="pie" />
          </div>
          <h1 class="no-data" v-if="!hasPiedata && !isLoadingPie">
            אין נתונים להצגה
          </h1>
        </div>
        <div class="mr-3">
          <GmapMap
            ref="googleMap"
            :center="{ lat: 31.31158, lng: 35.0421 }"
            :zoom="7"
            :options="{
              styles: styles,
            }"
            style="width: 100%; height: 100%"
          >
            <gmap-info-window
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen = false"
            >
              <div>
                <div class="info-window" v-if="marker && !markerIsLoading">
                  <ul>
                    <li>
                      <label>לוחית רישוי: </label>'{{ marker.Vehicle.Name }}
                    </li>
                    <li>
                      <label>כתובת: </label> {{ marker.Address }},
                      {{ marker.Municipality.Name }}
                    </li>
                    <li>
                      <label>נלקח בתאריך: </label
                      ><span class="ltr inline-block"></span>
                    </li>
                    <li>
                      <label>סכום לתשלום: </label>{{ marker.CurrentAmount }}
                    </li>
                    <li><label>סיבה: </label>{{ marker.Reason || "אין" }}</li>
                    <li><label>סטטוס: </label>{{ marker.Status.Name }}</li>
                    <div class="buttons">
                      <b-button
                        @click="watchReport(marker.Id)"
                        type="is-primary"
                        >הצג דוח</b-button
                      >
                      <b-button
                        @click="
                          showStreet(marker.AddressLat, marker.AddressLon)
                        "
                        type="is-primary"
                        >הצג רחוב</b-button
                      >
                    </div>
                  </ul>
                </div>
                <div v-if="markerIsLoading">טוען...</div>
              </div>
            </gmap-info-window>
            <gmap-heatmap-layer
              :data="heatMarks"
              :options="{ maxIntensity: 100, dissipating: true }"
            />
            <gmap-marker
              :key="m.Id"
              v-for="(m, i) in markers"
              :position="m.position"
              :visible="m.visible"
              :clickable="true"
              :draggable="false"
              @click="toggleInfoWindow(m, i)"
            ></gmap-marker>
          </GmapMap>
        </div>
      </div>
      <div class="Line-Chart">
        <b-loading
          :is-full-page="false"
          v-model="isLoadingLine"
          :can-cancel="false"
        />
        <div v-if="!isLoadingLine">
          <LineChart
            :height="60"
            v-if="lineChart"
            :chart-data="lineChart.data"
            :options="lineChart.options"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Pie from "@/components/Charts/Pie.vue";
import LineChart from "@/components/Charts/Line.vue";
import { gmapApi } from "gmap-vue";
import ReportService from "@/services/ReportService";
import StatisticsService from "@/services/StatisticsService";
// import moment from 'moment';
import axios from "axios";
import { mapGetters } from "vuex";

const cancellations = [];

export default {
  name: "Dashboard",
  computed: {
    google: gmapApi,
    hasPiedata() {
      return this.pie?.data.reduce((a, b) => a + b);
    },
    isLeasingCompany() {
      return this.$store.state.profile?.IsLeasingCompany;
    },
    ...mapGetters("dashboard", ["mapData", "lineChartData", "getPieData"]),
  },
  data() {
    return {
      mapInstance: null,
      marker: null,
      markerIsLoading: false,
      infoWindowPos: null,
      currentMidx: null,
      infoOptions: {
        content: "",
        // optional: offset infowindow so it visually sits nicely on top of our marker
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      infoWinOpen: false,
      isLoadingLine: false,
      isLoadingPie: false,
      styles: [
        {
          featureType: "landscape.natural",
          elementType: "geometry.fill",
          stylers: [{ visibility: "on" }, { color: "#e0efef" }],
        },
        {
          featureType: "poi",
          elementType: "geometry.fill",
          stylers: [
            { visibility: "on" },
            { hue: "#1900ff" },
            { color: "#c0e8e8" },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ lightness: 100 }, { visibility: "simplified" }],
        },
        {
          featureType: "road",
          elementType: "labels",
          stylers: [{ visibility: "on" }],
        },
        {
          featureType: "transit.line",
          elementType: "geometry",
          stylers: [{ visibility: "on" }, { lightness: 700 }],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [{ color: "#7dcdcd" }],
        },
      ],
      pie: null,
      lineChart: null,
      markers: [],
      heatMarks: [],
      managedVehicleCount: "-",
      unManagedVehicleCount: "-",
      averagePerVehicle: "-",
      unpaidReportCount: "-",
      unpaidAmount: "-",
    };
  },
  components: {
    Pie,
    LineChart,
  },
  async created() {
    await this.$store.dispatch("dashboard/loadStorage");
    this.getManagedVehicleCount();
    this.getUnManagedVehicleCount();
    // this.getAveragePerVehicle();
    this.getUnpaidReportCount();
    this.getUnpaidAmount();
    this.getPie();
    this.getLineChart();
  },
  mounted() {
    const self = this;
    this.$refs.googleMap.$mapPromise.then((map) => {
      this.mapInstance = map;
      this.getReports();
      map.addListener("zoom_changed", () => {
        const zoom = map.getZoom();
        if (zoom >= 12) {
          for (let i = 0; i < self.markers.length; i += 1) {
            self.markers[i].setVisible(true);
          }
        } else if (zoom < 12) {
          for (let i = 0; i < self.markers.length; i += 1) {
            self.markers[i].setVisible(false);
          }
        }
      });
    });
  },
  methods: {
    showStreet(lat, lng) {
      const panorama = this.mapInstance.getStreetView();
      panorama.setPosition({ lat, lng });
      panorama.setPov({
        heading: 265,
        pitch: 0,
      });
      panorama.setVisible(true);
    },
    watchReport(reportId) {
      this.$router.push({ path: `/reports/${reportId}` });
    },
    toggleInfoWindow(marker, index) {
      this.marker = null;
      this.markerIsLoading = true;
      this.infoWindowPos = marker.position;
      if (this.currentMidx === index) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = index;
      }
      ReportService.getReport(marker.customData.Id)
        .then((res) => {
          const { data } = res;
          this.marker = data;
        })
        .finally(() => {
          this.markerIsLoading = false;
        });
    },
    async getLineChart() {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      cancellations.push(source);
      this.isLoadingLine = true;

      if (this.$store.state.dashboard?.lineChartData === null) {
        await this.$store.dispatch("dashboard/getLineChartData");
      }
      const data = this.lineChartData;
      this.isLoadingLine = false;
      this.lineChart = {
        data: {
          labels: [
            "ינואר",
            "פברואר",
            "מרץ",
            "אפריל",
            "מאי",
            "יוני",
            "יולי",
            "אוגוסט",
            "ספטמבר",
            "אוקטובר",
            "נובמבר",
            "דצמבר",
          ],
          datasets: data.length
            ? [
                {
                  label: `כמות דוחות לשנת ${data[0].Year}`,
                  backgroundColor: "#7dcdcdc7",
                  data: data[0].Months.map((item) => item.Count),
                },
                {
                  label: `כמות דוחות לשנת ${data[1].Year}`,
                  backgroundColor: "#193441",
                  data: data[1].Months.map((item) => item.Count),
                },
              ]
            : [],
        },
        options: {
          layout: {
            padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
          tooltips: {
            enabled: true,
            intersect: "nearest",
            position: "nearest",
            titleFontSize: 16,
            titleMarginBottom: 10,
            titleSpacing: 10,
            bodyFontSize: 16,
            bodySpacing: 8,
            xPadding: 10,
            yPadding: 10,
            callbacks: {
              label(tooltipItem, theData) {
                let label =
                  theData.datasets[tooltipItem.datasetIndex].label || "";

                if (label) {
                  label += ": ";
                }
                label += tooltipItem.yLabel;

                label += " (";
                const calc =
                  data[tooltipItem.datasetIndex].Months[tooltipItem.index]
                    .Amount * 100;
                label += Math.round(calc) / 100;
                label += " שח)";

                return label;
              },
            },
          },
          legend: {
            display: true,
            labels: {
              fontColor: "black",
              fontSize: 17,
            },
            position: "top",
          },
          scales: {
            display: false,
            showLines: false,
            padding: 0,
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  fontColor: "#000",
                  fontSize: 15,
                },
              },
            ],
            yAxes: [
              {
                id: "y-axis-1",
                type: "linear",
                position: "left",
                gridLines: {
                  display: false,
                },
                ticks: {
                  fontColor: "#000",
                  fontSize: 15,
                },
              },
            ],
          },
        },
      };
    },
    async getPie() {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      cancellations.push(source);
      this.isLoadingPie = true;
      if (this.getPieData === null) {
        await this.$store.dispatch("dashboard/getPieData");
      }
      const data = this.getPieData;
      this.isLoadingPie = false;
      this.pie = {
        labels: data.map((item) => item.Status.Name),
        datasets: [
          {
            backgroundColor: ["#FFC04C", "#5bc18e", "#e67c7c", "#7DCDCD"],
            data: data.map((item) => item.ReportCount),
          },
        ],
        data: data.map((item) => item.ReportCount),
      };
    },
    async getManagedVehicleCount() {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      cancellations.push(source);

      const { data } = await StatisticsService.managedVehicleCount(
        source.token
      );
      this.managedVehicleCount = data;
    },
    async getUnManagedVehicleCount() {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      cancellations.push(source);

      const { data } = await StatisticsService.unmanagedVehicleCount(
        source.token
      );
      this.unManagedVehicleCount = data;
    },
    async getAveragePerVehicle() {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      cancellations.push(source);

      const { data } = await StatisticsService.averagePerVehicle(source.token);
      this.averagePerVehicle = parseInt(data.AverageCount, 10);
    },
    async getUnpaidReportCount() {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      cancellations.push(source);

      const { data } = await StatisticsService.unpaidReportCount(source.token);
      this.unpaidReportCount = data;
    },
    async getUnpaidAmount() {
      const { CancelToken } = axios;
      const source = CancelToken.source();
      cancellations.push(source);

      const { data } = await StatisticsService.unpaidAmount(source.token);
      this.unpaidAmount = parseFloat(data.toFixed(2));
    },
    async getReports() {
      if (this.$store.state.dashboard?.mapData === null) {
        await this.$store.dispatch("dashboard/getMapData");
      }
      const data = this.mapData;
      const bounds = new this.google.maps.LatLngBounds();
      this.heatMarks = data
        .map((item) => {
          if (item.AddressLat && item.AddressLon) {
            const marker = this.createMarker(item);
            this.markers.push(marker);

            // extend the bounds to include each marker's position
            bounds.extend(marker.position);

            return new this.google.maps.LatLng(
              item.AddressLat,
              item.AddressLon
            );
          }
          return null;
        })
        .filter((i) => i);

      this.$refs.googleMap.fitBounds(bounds);
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    createMarker(item) {
      let icon;
      switch (item.Status) {
        case "כפל קנס":
          icon =
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|E67C7C";
          break;
        case "שולם":
          icon =
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|5BC18E";
          break;
        case "פתוח":
          icon =
            "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FFC04C";
          break;
        default:
          break;
      }
      const marker = new this.google.maps.Marker({
        position: new this.google.maps.LatLng(item.AddressLat, item.AddressLon),
        map: this.mapInstance,
        visible: false,
        opacity: 1,
        icon,
        customData: item,
      });

      return marker;
    },
  },
  destroyed() {
    console.log("destroyed");
    cancellations.forEach((i) => i.cancel());
  },
};
</script>
<style scoped lang="scss">
.Dashboard__View {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.DashBoard__Widgets__Indicators {
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  h3 {
    font-size: 23px;
  }
  .DashBoard__Widgets__Indicators__Item {
    width: 100%;
    border: 1px solid #2e3f506b;
    margin: 10px;
    height: 141.5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }
  .DashBoard__Widgets__Indicators__Item__Box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    direction: ltr;
    font-size: 30px;

    .amount {
      font-size: 30px;
      text-align: center;
      display: block;
      width: 100%;
    }

    ::v-deep .icon i.mdi::before {
      font-size: 40px;
    }
  }
}
.Charts {
  display: flex;
  padding: 10px;
  position: relative;
  z-index: 1;

  & > div {
    height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 3px;
    position: relative;
  }

  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: 22px;
  }
}
.Line-Chart {
  height: calc(100vh - 510px - 200px);
  z-index: 2;
  width: 100%;
  position: relative;
}
::v-deep .info-window {
  padding: 10px;
  font-size: 17px;

  .buttons {
    display: flex;
    justify-content: center;
    padding-top: 10px;

    & > button {
      margin: 0 5px;
    }
  }
}
</style>
