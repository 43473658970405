var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Dashboard__View" }, [
    _c("div", { staticClass: "Dashboard__Widgets" }, [
      _c("div", { staticClass: "DashBoard__Widgets__Indicators" }, [
        _vm.$store.state.profile.sosna
          ? _c("div", { staticClass: "DashBoard__Widgets__Indicators__Item" }, [
              _vm._m(0)
            ])
          : _vm._e(),
        _c("div", { staticClass: "DashBoard__Widgets__Indicators__Item" }, [
          _c("h3", [_vm._v("רכבים מנוהלים")]),
          _c(
            "div",
            { staticClass: "DashBoard__Widgets__Indicators__Item__Box" },
            [
              _c("div", { staticClass: "amount" }, [
                _vm._v(" " + _vm._s(_vm.managedVehicleCount) + " ")
              ])
            ]
          )
        ]),
        _c("div", { staticClass: "DashBoard__Widgets__Indicators__Item" }, [
          _c("h3", [_vm._v("רכבים לא מנוהלים")]),
          _c(
            "div",
            { staticClass: "DashBoard__Widgets__Indicators__Item__Box" },
            [
              _c("div", { staticClass: "amount" }, [
                _vm._v(" " + _vm._s(_vm.unManagedVehicleCount) + " ")
              ])
            ]
          )
        ]),
        _c("div", { staticClass: "DashBoard__Widgets__Indicators__Item" }, [
          _c("h3", [_vm._v("דוחות שלא שולמו")]),
          _c(
            "div",
            { staticClass: "DashBoard__Widgets__Indicators__Item__Box" },
            [
              _c("div", { staticClass: "amount" }, [
                _vm._v(" " + _vm._s(_vm.unpaidReportCount) + " ")
              ])
            ]
          )
        ]),
        _c("div", { staticClass: "DashBoard__Widgets__Indicators__Item" }, [
          _c("h3", [_vm._v("סכום לתשלום")]),
          _c(
            "div",
            { staticClass: "DashBoard__Widgets__Indicators__Item__Box" },
            [
              _c("div", { staticClass: "amount" }, [
                _vm._v(" " + _vm._s(_vm._f("currency")(_vm.unpaidAmount)) + " ")
              ])
            ]
          )
        ])
      ]),
      _c("div", { staticClass: "Charts" }, [
        _c(
          "div",
          { staticClass: "ml-3" },
          [
            _c("b-loading", {
              attrs: { "is-full-page": false, "can-cancel": false },
              model: {
                value: _vm.isLoadingPie,
                callback: function($$v) {
                  _vm.isLoadingPie = $$v
                },
                expression: "isLoadingPie"
              }
            }),
            !_vm.isLoadingPie && _vm.hasPiedata
              ? _c(
                  "div",
                  [
                    _c("h1", [
                      _vm._v(
                        'פילוח דוחות ע"פ סטטוס לשנת ' +
                          _vm._s(new Date().getFullYear())
                      )
                    ]),
                    _vm.pie
                      ? _c("Pie", { attrs: { "chart-data": _vm.pie } })
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e(),
            !_vm.hasPiedata && !_vm.isLoadingPie
              ? _c("h1", { staticClass: "no-data" }, [
                  _vm._v(" אין נתונים להצגה ")
                ])
              : _vm._e()
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mr-3" },
          [
            _c(
              "GmapMap",
              {
                ref: "googleMap",
                staticStyle: { width: "100%", height: "100%" },
                attrs: {
                  center: { lat: 31.31158, lng: 35.0421 },
                  zoom: 7,
                  options: {
                    styles: _vm.styles
                  }
                }
              },
              [
                _c(
                  "gmap-info-window",
                  {
                    attrs: {
                      position: _vm.infoWindowPos,
                      opened: _vm.infoWinOpen
                    },
                    on: {
                      closeclick: function($event) {
                        _vm.infoWinOpen = false
                      }
                    }
                  },
                  [
                    _c("div", [
                      _vm.marker && !_vm.markerIsLoading
                        ? _c("div", { staticClass: "info-window" }, [
                            _c("ul", [
                              _c("li", [
                                _c("label", [_vm._v("לוחית רישוי: ")]),
                                _vm._v(
                                  "'" + _vm._s(_vm.marker.Vehicle.Name) + " "
                                )
                              ]),
                              _c("li", [
                                _c("label", [_vm._v("כתובת: ")]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.marker.Address) +
                                    ", " +
                                    _vm._s(_vm.marker.Municipality.Name) +
                                    " "
                                )
                              ]),
                              _c("li", [
                                _c("label", [_vm._v("נלקח בתאריך: ")]),
                                _c("span", { staticClass: "ltr inline-block" })
                              ]),
                              _c("li", [
                                _c("label", [_vm._v("סכום לתשלום: ")]),
                                _vm._v(_vm._s(_vm.marker.CurrentAmount) + " ")
                              ]),
                              _c("li", [
                                _c("label", [_vm._v("סיבה: ")]),
                                _vm._v(_vm._s(_vm.marker.Reason || "אין"))
                              ]),
                              _c("li", [
                                _c("label", [_vm._v("סטטוס: ")]),
                                _vm._v(_vm._s(_vm.marker.Status.Name))
                              ]),
                              _c(
                                "div",
                                { staticClass: "buttons" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.watchReport(_vm.marker.Id)
                                        }
                                      }
                                    },
                                    [_vm._v("הצג דוח")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { type: "is-primary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.showStreet(
                                            _vm.marker.AddressLat,
                                            _vm.marker.AddressLon
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("הצג רחוב")]
                                  )
                                ],
                                1
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.markerIsLoading
                        ? _c("div", [_vm._v("טוען...")])
                        : _vm._e()
                    ])
                  ]
                ),
                _c("gmap-heatmap-layer", {
                  attrs: {
                    data: _vm.heatMarks,
                    options: { maxIntensity: 100, dissipating: true }
                  }
                }),
                _vm._l(_vm.markers, function(m, i) {
                  return _c("gmap-marker", {
                    key: m.Id,
                    attrs: {
                      position: m.position,
                      visible: m.visible,
                      clickable: true,
                      draggable: false
                    },
                    on: {
                      click: function($event) {
                        return _vm.toggleInfoWindow(m, i)
                      }
                    }
                  })
                })
              ],
              2
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "Line-Chart" },
        [
          _c("b-loading", {
            attrs: { "is-full-page": false, "can-cancel": false },
            model: {
              value: _vm.isLoadingLine,
              callback: function($$v) {
                _vm.isLoadingLine = $$v
              },
              expression: "isLoadingLine"
            }
          }),
          !_vm.isLoadingLine
            ? _c(
                "div",
                [
                  _vm.lineChart
                    ? _c("LineChart", {
                        attrs: {
                          height: 60,
                          "chart-data": _vm.lineChart.data,
                          options: _vm.lineChart.options
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "DashBoard__Widgets__Indicators__Item__Box" },
      [
        _c("div", { staticClass: "amount" }, [
          _c("img", {
            staticStyle: { "min-width": "80px" },
            attrs: { src: require("../assets/Sosna.jpg"), alt: "" }
          })
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }